.TrendingTopicsCard {
    background-color: #fff;
    padding: 12px;
    border-radius: 10px;
    box-shadow: var(--cardShadow);
}

.TrendingTopicsCard > h5 {
    font-size: 16px;
    font-weight: 700;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.TrendingTopicsCard > p {
    font-size: 14px;
}

.TrendingTopicsCard .TopicInfo {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}