.badgeStyle {
    border-radius: 4px;
    font-size: 10px;
    padding: 6px 8px;
    line-height: 12px;
    display: flex;
    align-items: center;
}

.badgeStyle.icon {
    padding: 2px 8px;
}

.badge-coins {
    background-color: #F6E68D;
    color: #AC6A04;
    border: 1px solid #AC6A04;
    font-weight: 500;
}

.badge-level {
    background-color: #F2F2F2;
    color: #A9A1A1;
    border: 1px solid #C8C8C8;
}

.badge-time {
    background-color: #F2F2F2;
    color: #A9A1A1;
    border: 1px solid #C8C8C8;
}

.badge-work {
    background-color: #ffffff;
    color: #A9A1A1;
    border: 1px solid #C8C8C8;
}