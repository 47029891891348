.profileView {
    background-color: #fff;
    padding: 1rem;
}

.profileView .ProfileHead {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding-bottom: 16px;
    position: relative;
}

.profileView .ProfileHead .editProfileButton {
    position: absolute;
    right: 10px;
    top: 0px;
}

.profileImageContainer {
    position: relative;
}

.profileImageContainer::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 4px solid #fff;
    background-color: #409348;
}

.profileView .ProfileHead img {
    max-width: 100px;
    border-radius: 50%;
}

.profileView .ProfileHead .UserName {
    font-size: 24px;
    margin-bottom: 0;
    margin-top: 8px;
}

.profileView .ProfileHead p {
    margin-bottom: 0;
    color: #757F87;
    font-size: 16px;
}

.profileView .JoinedLocation {
    display: flex;
    justify-content: space-between;
}

.profileView .JoinedLocation p {
    margin-bottom: 0;
    display: flex;
    gap: 8px;
    align-items: center;
    color: #757F87;
}

.profileView .UserProgressNo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    border: 1px solid #EBEEF1;
    border-left: none;
    border-right: none;
    height: 80px;
}

.profileView .UserProgressNo .Item {
    flex-shrink: 0;
    flex-grow: 1;
} 
.profileView .UserProgressNo h3{ 
    margin: 0;
    text-align: center;
}

.profileView .UserProgressNo p {
    text-align: center;
    margin: 0;
    color: #757F87;
}

.userAction {
    display: flex;
    gap: 4px;
}

.userAction button {
    flex-grow: 1;
    font-weight: 700;
}

.userAction button.btn-outline-secondary {
    border-color: #EBEEF1 !important;
    color: #000;
}

.userAchievements {
    margin: 22px 0;
    gap: 14px;
    display: flex;
    flex-direction: column;
}

.userAchievements .achievements {
    display: flex;
    align-items: center;
}

.userAchievements .achievements .icon {
    max-width: 40px;
    flex-grow: 1;
    flex-shrink: 1;
}

.userAchievements .achievements .achievementData {
    flex-grow: 1;
}

.userAchievements .achievements .achievementData h4 {
    margin-bottom: 0;
    font-weight: 700;
}

.userAchievements .achievements .achievementData p {
    margin: 0;
    line-height: 18px;
    color: #9CA3AF;
}

.UsersPriority {
    margin: 22px 0 0 0;
}

h4.profileTitle {
    font-weight: 700;
    margin-bottom: 6px;
}

.UsersPriority .priorities {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.UsersPriority .priorities .badge.bg-primary {
    background-color: transparent !important;
    color: #000;
    border: 1px solid #EBEEF1;
    padding: 12px;
}

.UserContactDetails {
    padding-top: 18px;
}

.UserContactDetails .links {
    display: flex;
    flex-direction: column;
    gap: 12px;
    line-height: 14px;
    margin-top: 10px;
}

.UserContactDetails .links a {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #000;
}