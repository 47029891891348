.resumeforms {
    background-color: #fff;
    margin-top: 10px;
}
.resumeforms .radioInputs .form-check-label::before,
.resumeforms .radioInputs .form-check-label::after {
    top: 50% !important;
    transform: translateY(-50%);
}

.resumeforms .radioInputs .form-check-label::after {
    border-color: #1165EF;
}

.resumeforms .radioInputs .form-check-input[type="radio"]~.form-check-label::before {
    background-image : url("data:image/svg+xml,%0A%3Csvg width='8px' height='8px' viewBox='0 0 8 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Ccircle id='Oval' fill='%231165EF' cx='4' cy='4' r='4'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E") !important;
}

.resumeforms .radioInputs .form-check-input:checked~.form-check-label:after {
    background-color: #fff;
    border-color: #1165EF !important;
}

.openfor {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
}

.openfor .form-check-label {
    padding-left: 20px;
    line-height: 20px;
}

.openfor .form-check-label::after {
    border-radius: 0;
    border-color: #a4a0a0;
}

.custom-file-upload label{
    background-color: #fff;
}

.custom-file-upload label span {
    color: #000 !important;
}


.activeFormButtons {
    display: flex;
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
}

.activeFormButtons .buttonContainer {
    display: flex;
    gap: 10px;
}

.activeFormButtons .buttonContainer button {
    border-radius: 20px;
    display: flex;
    gap: 6px;
    padding: 4px 8px;
    width: fit-content;
    flex-shrink: 0;
}

.formContianer {
    background-color: #EFF7FF;
}

.FormHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AddNextButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 12px ;
    padding-bottom: 3rem;
}

.AddNextButton button {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 8px;
    box-shadow: -2px -2px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
    font-weight: 600;
}

.mobileMainFooter .btn-link {
    box-shadow: -2px -2px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
}