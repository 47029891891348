.quizCard {
    display: flex;
    background-color: #fff;
    /* padding: 10px 0 10px 10px; */
    border-radius: 10px;
    overflow: hidden;
    align-items: center;
    position: relative;
    box-shadow: var(--cardShadow);
}

.quizCard .data {
    padding: 10px;
    width: calc(100% - 25px);
}

.quizCard button {
    width: 25px;
    padding: 0;
    height: 100% !important;
    display: block;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    background-color: var(--primaryColor);
    flex-grow: 1;
    flex-shrink: 0;
    border-radius: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.quizCard.disabled button {
    background-color: #C2C2C2;
}
.data-badges {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 10px;
}

.quizCard h5 {
    font-weight: 700;
    font-size: 16px;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    margin-bottom: 5px;
}