.DiscussCard {
    padding: 10px;
    background-color: #fff;
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.DiscussCard .DiscussCardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.DiscussCard .DiscussCardHeader .DiscussAddedTime {
    margin: 0;
    font-size: 12px;
}

.DiscussCard .DiscussMessage {
    font-size: 14px;
    line-height: 18px;
}

.DiscussCard .DiscussionAction {
    display: flex;
    justify-content: end;
}