.ContentCard {
    background-color: #fff;
    display: flex;
    gap: 10px;
    flex-direction: column;

}

.ContentCard h5,
.ContentCard h4 {
    margin-bottom: 0;
}


.ContentCard .ContentDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.ContentCard .ContentDetails h5 {
    display: flex;
    gap: 6px;
    font-size: 12px;
    align-items: center;
    color: #A9A1A1;
}

.ContentCard .ContentDetails h4 {
    font-weight: 700;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}


.ContentCard .ImageContainer img{
    aspect-ratio: 3/1.7;
    object-fit: contain;
}