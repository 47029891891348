.CoinsView {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #fff;
    margin-top: 8px;
}
.CoinsView .Totalcoins {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
}

.CoinsView .Totalcoins span {
    font-size: 35px;
    font-weight: 500;
}

.CoinsView > p {
    text-align: center;
    color: #757F87;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.transactionsHeader, 
.transactionsEntry {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.transactionsHeader h4 {
    font-weight: 700;
}
.transactionsHeader h4,
.transactionsEntry p {
    color: #757F87;
    font-size: 14px;
    margin: 0;
}

.transactionsEntry > div {
    min-width: 100px;
    display: flex;
    justify-content: end;
}

