.QuizQuestionCard .QuestionTitle {
    font-weight: 700;
    color: black;
    line-height: 19px;
}

.QuizQuestionCard .QuestionOptions .form-check{
    background-color: #fff;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #5A5B5F33;
}

.QuizQuestionCard .QuestionOptions .form-check label {
    font-weight: 500;
    padding-left: 22px;
    line-height: 16px;
}
.QuizQuestionCard .QuestionOptions .form-check-input:disabled~.form-check-label,
.QuizQuestionCard .QuestionOptions .form-check-input[disabled]~.form-check-label {
    opacity: 1 !important;
}

.QuizQuestionCard .QuestionOptions .form-check .form-check-label::before,
.QuizQuestionCard .QuestionOptions .form-check .form-check-label::after {
    top: 50% !important;
    transform: translateY(-50%);
}

.QuizQuestionCard .QuestionOptions .form-check .form-check-label::after {
    border-color: black;
}

.QuizQuestionCard .QuestionOptions .form-check-input[type="radio"]~.form-check-label::before {
    background-image : url("data:image/svg+xml,%0A%3Csvg width='8px' height='8px' viewBox='0 0 8 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Ccircle id='Oval' fill='%23000000' cx='4' cy='4' r='4'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E") !important;
}

.QuizQuestionCard .QuestionOptions .form-check-input:checked~.form-check-label:after {
    background-color: #fff;
    border-color: black !important;
}

.QuizQuestionCard .QuestionOptions .form-check:has(.form-check-input:checked) {
    background-color: #DCEFFF !important;
}

.QuizQuestionCard.submitted .QuestionOptions .form-check.correct {
    background-color: #94D6A3 !important;
}

.QuizQuestionCard.submitted .QuestionOptions .form-check.wrong {
    background-color: #F5A6A9 !important;
}

.QuestionExplanation {
    margin-top: 18px;
}

.QuestionExplanation h5 {
    font-weight: 700;
    font-size: 1rem;
}

.QuestionExplanation p {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #000;
}