.ReleventQCard {
    display: flex;
    flex-direction: column;
    gap: 14px;
    background-color: #ffffff;
}

.ReleventQCard .headerContianer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.ReleventQCard .headerContianer .headerCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ReleventQCard .headerContianer .headerCard p {
    margin-bottom: 0;
    font-weight: 500;
}

.ReleventQCard .headerContianer .questiontitle {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 700;
}

.ReleventQCard .headerContianer .questionDescription {
    line-height: 16px;
    margin-bottom: 0;
    font-size: 13px;
}

.ReleventQCard .badgeStyle {
    width: fit-content;
}

.ReleventQCard .cardActions {
    display: flex;
    align-items: center;
    gap: 8px;
}
.ReleventQCard .TopCommentText {
    flex-grow: 1;
    background-color: #F2F2F2;
    padding: 8px;
    border-radius: 20px;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    font-weight: 500;
}
.ReleventQCard .cardActions .actions {
    min-width: 145px;
}

.ReleventQCard .QActions {
    display: flex;
    gap: 8px;
}

.ReleventQCard .QActions button {
    flex-grow: 1;
}

.ReleventQCard .questionbadges {
    display: flex;
    align-items: center;
    gap: 4px;
}