.SortByContainer {
    position: relative;
}

.SortBy {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 6px;
    background-color: #fff;
    height: 50px;
}
.SortBy.activeSort {
    background-color: #DDEAFF;
}
.SortBy svg {
    width: 40px;
}
.SortBy h4 {
    flex-grow: 1;
    margin: 0;
}

/* .SortByContainer .SortByAll {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 50px;
} */
.SortByAll .SortByOption {
    padding: 10px;
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
}

.SortByAll .SortByOption div {
    width: 40px;
}

.SortByOption {
    border-bottom: 1px solid #F2F2F2;
}