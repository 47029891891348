.QuizeHeader {
    display: flex;
    width: 100%;
    flex-grow: 1;
    justify-content: space-between;
    padding: 0 10px;
    align-items: center;
    gap: 8px;
}

.QuizeHeader h4 {
    margin: 0;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    font-weight: 700;
}

.QuizeHeader .Timer {
    display: flex;
    gap: 4px;
    width: clamp(80px, 20%, 100px);
    justify-content: end;
}

.QuizeHeader .Timer p {
    margin: 0;
    font-weight: 700;
}

.QuizActions {
    display: flex;
    width: 100%;
    padding: 0 10px;
    gap: 8px;
}

.QuizActions button {
    width: 100%;
    font-weight: 700;
}


.QuizContainer {
    padding-top: 2rem;
}

.ConfirmationQuizModal {
    display: flex;
    gap: 16px;
    flex-direction: column;
}

.ConfirmationQuizModal .TimeLeft {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ConfirmationQuizModal .TimeLeft  .Left {
    display: flex;
    align-items: center;
    gap: 8px;
}

.ConfirmationQuizModal .TimeLeft  .Left p {
    margin: 0;
    font-weight: 500;
}

.ConfirmationQuizModal .TimeLeft .Time {
    margin: 0;
    font-weight: 800;
    color: #1165EF;
}

.dividerLine {
    height: 1px;
    background-color: #DCDCDC;
}

.ConfirmationQuizModal .Textline {
    font-weight: 700;
    margin: 0;
}

.ModalAction {
    width: 100%;
    display: flex;
    gap: 8px;
    margin-top: 16px;
}

.ModalAction button {
    flex-grow: 1;
}

.SubmitQuizModal > p {
    text-align: center;
    font-weight: 700;
}
.SubmitQuizModal {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.SubmitQuizModal .submitInfo {
    display: flex;
    align-items: center;
    gap: 8px;
}

.SubmitQuizModal .submitInfo p {
    margin-bottom: 0;
    font-weight: 500;
}

.SubmitQuizModal .submitInfo span {
    margin-left: auto;
    margin-right: 0;
    font-weight: 600;
}

.SubmitQuizModal .submitInfo span.coins {
    color: #F6D834;
}

.SubmitQuizModal .submitInfo span.score {
    color: #1165EF;
}