
/* HTML: <div class="loader"></div> */
.loader {
    width: 40px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #C4C4C4;
    box-shadow: 0 0 0 0 #0004;
    animation: l1 1s infinite;
  }
  @keyframes l1 {
      100% {box-shadow: 0 0 0 10px #0000}
  }

.ResumeView {
    background-color: #ffffff;
    display: flex;
    gap: 16px;
    flex-direction: column;
}

.ResumeView .ResumeProfile {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ResumeView .ResumeProfile .ProfileImage {
    width: 130px;
    border-radius: 50%;
    overflow: hidden;
}

.ResumeView .ResumeProfile .ProfileImage img {
    width: 100%;
}

.ResumeView .ResumeProfile .NameButton {
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.ResumeView .ResumeProfile .NameButton button {
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-right: 0;
}

.ResumeView .ResumeProfile .NameButton .Name {
    text-align: end;
    margin: 0;
    font-weight: 700;
    font-size: 23px;
}

.ResumeView .ResumeProfile .NameButton .Location {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: end;
    color: #757F87;
}

.profileDescription {
    font-size: 16px;
    line-height: 18px;
}

.profileContactInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px 0;
    border: 1px solid #EBEEF1;
    border-left: none;
    border-right: none;
}

.profileContactInfo a {
    display: flex;
    gap: 6px;
    align-items: center;
    color: #757F87;
}

.profileSkillsLanguage h4 {
    font-weight: 600;
}

.profileSkillsLanguage .priorities{
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.profileSkillsLanguage .priorities .badge.bg-primary {
    background-color: transparent !important;
    color: #000;
    border: 1px solid #EBEEF1;
    padding: 12px;
}

.ProfileAchiv .ProfileAchivTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ProfileAchiv .ProfileAchivTitle h4 {
    margin: 0;
    font-weight: 600;
}

.ProfileAchiv .ProfileAchivTitle button {
    font-size: 14px;
}

.ProfileAchiv .Achiv {
    display: grid;
    grid-template-areas: 'ProfileImg ProfileInfo ProfileInfo ProfileInfo ProfileInfo'
                          'ProfileLine ProfileLine ProfileLine ProfileLine ProfileLine';
    gap: 8px;
    border-top: 1px solid #EBEEF1;
    padding: 10px 0 0 0;
}

.ProfileAchiv .Achiv .AchivImg {
    grid-area: ProfileImg;
    width: 95px;
    padding: 0 10px;
    max-width: 96px;
}

.ProfileAchiv .Achiv .AchiDesc {
    grid-area: ProfileLine;
}

.ProfileAchiv .Achiv .AchivImg img {
    width: 100%;
}

.ProfileAchiv .Achiv .AchivInfo {
    grid-area: ProfileInfo;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.ProfileAchiv .Achiv .AchivInfo p,
.ProfileAchiv .Achiv .AchivInfo h5 {
    margin: 0;
}

.ProfileAchiv .Achiv .AchivInfo .AchivTitle {
    font-weight: 600;
}

.ProfileAchiv .Achiv .AchivInfo p.iconData {
    display: flex;
    gap: 4px;
    align-items: center;
    color: #757F87;
    line-height: 16px;
}

.ProfileAchiv .Achiv p {
    margin: 0;
    font-weight:400;
    font-size: 12px;
    line-height: 16px;
}

.ProfileAchiv {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.AltScreens {
    height: 100%;
}
.SavingScreen, .SavedScreen {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    background-color: #ffffff;
}

.SavingScreen p,
.SavedScreen p {
    margin: 0;
    font-weight: 700;
    color: #000;
    font-size: 18px;
    margin-top: 10px;
}

.SavingScreen span,
.SavedScreen span {
    color: #757F87;
    line-height: 16px;
}

.SavedScreen button {
    margin-top: 3rem;
    border-color: #EBEEF1 !important;
    color: #0E1E2F;
}