.DiscussDetailsView {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.NoComments {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Comments {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.AddCommentInput form {
    display: flex;
    align-items: center;
    gap: 8px;
}

.AddCommentInput {
    width: 100%;
    flex-grow: 1;
    padding: 0 10px;
}

.AddCommentInput input {
    background-color: #F2F2F2 !important;
    border-color: #DEDEDE;
}

.AddCommentInput input::placeholder {
    color: #7B7B7B;
}

.AddCommentInput form button {
    width: 40px !important;
    flex-grow: 1;
}

.DiscussDetailsView .appBottomMenu {
    flex-direction: column;
}

.DiscussDetailsView .AddCommentOrReply {
    padding: 10px;
}
.DiscussDetailsView .AddCommentOrReply .AddCommentOrReplyHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.DiscussDetailsView .AddCommentOrReply .AddCommentOrReplyHeader  h4{
    font-weight: 600;
    margin: 0;
    font-size: 18px;
}

.DiscussDetailsView .AddCommentOrReply p {
    font-size: 12px;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 14px;
    margin: 0;
}