.registration .Logo {
    display: block;
    margin: 2rem auto;
    height: 30px;
}

.registration .registrationForm {
    min-height: calc(var(--100vh) - (30px + 4rem + 2rem));
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.registration .registrationForm button {
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0; */
}

.registration {
    height: var(--100vh);
    overflow-y: auto;
}