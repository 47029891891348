.ReferralCard {
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.ReferralCard img {
    width: 100px;
}

.ReferralCard > div {
    display: flex;
    flex-direction: column;
}
.ReferralCard > div.action p {
    font-size: 14px;
}

.ReferralModal {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
}

.ReferralModal img {
    width: 200px;
}

.ReferralModal h5 {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
}

.ReferralModal p {
    margin: 0;
}