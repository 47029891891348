.EventsCard {
    background-color: #fff;
    padding: 1rem;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.EventsCard img {
    width: 100%;
}

.EventsCard h4 {
    margin: 0;
    font-weight: 700;
}

.EventsCard .data-buttons {
    display: flex;
    gap: 8px;
}
.EventsCard .data-buttons button {
    width: 100%;
}