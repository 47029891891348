.workcard {
    background-color: #fff;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.workcard .workPositionName {
    margin: 0;
}

.workCompanyTime {
    display: flex;
    justify-content: space-between;
}

.workCompanyTime .company {
    display: flex;
    align-items: center;
    gap: 6px;
}

.workCompanyTime .company img {
    width: 26px;
    border-radius: 50%;
}
.workCompanyTime .company h5 {
    margin: 0;
}

.workCompanyTime .time p {
    font-size: 14px;
    margin: 0;
    color: #000;
}

.workcard .positionDetails {
    display: flex;
    flex-wrap: wrap;
    gap: 6px 14px;
}

.workcard .positionDetails .detail {
    display: flex;
    align-items: center;
    gap: 6px;
}

.workcard .positionDetails .detail p {
    margin: 0;
    color: #000;
}

.workcard .JobAction {
    height: 50px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}
.workcard .JobAction .userAction {
    flex-shrink: 0;
}

.workcard .JobAction.detailsButton {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    height: 60px;
    box-shadow: -1px -5px 12px 1px rgb(0 0 0 / 17%);
    background-color: #fff;
}

.workcard .JobAction .ApplyAction {
    flex-grow: 1;
    width: 100%;
    max-width: 250px;
}

.workcard .JobAction .ApplyAction button {
    width: 100%;
}

.AboutCompany {
    line-height: 18px;
}

.productTrackModule {
    margin-top: 6px;
}

.productTrackModule h5 {
    margin-bottom: 0;
    font-weight: 700;
    font-style: italic;
}

.productTrackModule p {
    line-height: 18px;
}