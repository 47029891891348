.authentication {
    height: var(--100vh);
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
}

.authentication .Logo {
    width: 100%;
    max-width: 200px;
    display: block;
    margin: 1rem auto 0 auto;
}

.authentication .LoginPlaceHolderImg {
    /* width: clamp(300px, 100%, 340px); */
    height: 40vh;
    aspect-ratio: 1;
    object-fit: cover;
}

/* @media (max-width: 376px) {
    .authentication .LoginPlaceHolderImg {
        width: clamp(250px, 100%, 290px);
    }
} */
.authentication .LoginContainer {
    flex-grow: 1;
    width: 100%;
}
.LoginForm {
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    background-color: #fff;
}

.inputContainer {
    display: flex;
    align-items: center;
    position: relative;
    gap: 4px;
}
.inputContainer.otpinputs {
    justify-content: space-between;
    max-width: 400px;
}
.otpinputs input {
    max-width: 50px;
    text-align: center;
} 

.inputContainer.showIcon input {
    padding-left: 40px;
}

.inputContainer span {
    position: absolute;
    left: 10px;
}

.authentication .CopyRight {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
    max-width: 360px;
    padding-bottom: 1rem;
}

.authentication .CopyRight p {
    margin: 0;
    text-align: center;
    font-size: 11px;
}

.authentication .CopyRight div {
    display: flex;
    gap: 6px;
    justify-content: space-around;
}

.authentication .CopyRight div a {
    color: black;
    text-decoration: underline;
    font-weight: 700;
    font-size: 12px;
}

.OTPSentOn {
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: center;
}

.OTPSentOn span {
    font-weight: 600;
    color: #000;
}

.OTPSentOn button {
    font-size: 14px;
    display: flex;
    gap: 4px;
}